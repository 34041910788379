.user-credits {
    background-color: rgba(59, 130, 246, 0.1);
    border-radius: 0.5rem;
    padding: 0.75rem;
    margin-bottom: 1rem;
  }
  
  .user-credits-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  
  .user-credits-label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--foreground);
  }
  
  .user-credits-value {
    font-size: 0.875rem;
    font-weight: 600;
    color: var(--foreground);
  }
  
  .progress-bar {
    height: 0.5rem;
    background-color: var(--muted);
    border-radius: 0.25rem;
    overflow: hidden;
  }
  
  .progress-bar-fill {
    height: 100%;
    background-color: var(--primary);
    transition: width 0.3s ease;
  }
  