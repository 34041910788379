html.training_mode .div_generate_button_container {
    display:none;
}

.logo .trademark {
    padding-top: 1px;
    font-size: 7px;
    margin-top: 11.5px;
    font-weight: bold;
    display: inline-block;
    vertical-align: top;
    padding: 0;
    line-height: 1;
    text-align: left;
}
@media (max-width:400px) {
    .logo .trademark {
        display:none;
    }
}
.logo-box {
    width:80px;
    height:30px;
    margin-right:7px;
    margin-top:-4px;
    vertical-align:middle;
    background: -webkit-linear-gradient(45deg, #680aca, #ff897e);
    background: linear-gradient(60deg,#f79533,#f37055,#ef4e7b,#a166ab,#5073b8,#1098ad,#07b39b,#6fba82);
    -webkit-animation: animatedgradient 6s ease infinite alternate;
    animation: animatedgradient 6s ease infinite alternate;
    background-size: 300% 300%;
    mask-image:url('/public/logo.svg');
    mask-repeat: no-repeat;
    mask-size: contain;
    -webkit-mask-image:url('/public/logo.svg');
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-size: contain;
        display:inline-block;
}

.header {
    z-index:50;
    height: var(--header-height);
    width: 100%;
    text-align: center;
    border-bottom: 1px solid var(--global-border-color);
    position: relative;
    background: var(--header-background-color);
}
html.guest .header {
    background:none;
    border:none;
}
@media (min-width:1000px) {
    html.guest .header {
        position:fixed;
        top:0;
    }
    html.guest .hero {
/*			margin-top:var(--header-height);*/
    }
}
.header-link {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-left:7px;
    margin-right:7px;
    display:inline-block;
    text-decoration: none;
    vertical-align:middle;
}
.header h1 {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    padding-left: 0;
    padding-right: 21px;
    line-height: 1.88;
    color: #fff;
    position: absolute;
    height: 34px;
    overflow: hidden;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    margin: 0;
    left: 21px;
    font-size: 21px;
    top: 50%;
    transform: translateY(-50%);
    background: -webkit-linear-gradient(45deg, #680aca, #ff897e);
    background: linear-gradient(60deg,#f79533,#f37055,#ef4e7b,#a166ab,#5073b8,#1098ad,#07b39b,#6fba82);
    -webkit-animation: animatedgradient 6s ease infinite alternate;
    animation: animatedgradient 6s ease infinite alternate;
    background-size: 300% 300%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.logo h1 {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    padding-left: 0;
    padding-right: 21px;
    line-height: 1.88;
    color: #fff;
    height: 34px;
    overflow: hidden;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    margin: 0;
    left: 21px;
    font-size: 21px;
    top: 50%;
    background: -webkit-linear-gradient(45deg, #680aca, #ff897e);
    background: linear-gradient(60deg,#f79533,#f37055,#ef4e7b,#a166ab,#5073b8,#1098ad,#07b39b,#6fba82);
    -webkit-animation: animatedgradient 6s ease infinite alternate;
    animation: animatedgradient 6s ease infinite alternate;
    background-size: 300% 300%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top:-3px;
}
@keyframes animatedgradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
.header h2 {
    margin: 0 auto;
    text-transform: uppercase;
    font-size: 12px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
}