
html.logged-in .footer-links {
    border-top: 1px solid var(--global-border-color);
    padding-top: 28px;
    width:100%;
    max-width:100%;
}
.footer-links {
    width:100%;
    max-width:calc(1200px - 21px);
    margin:14px auto;
    margin-top:56px;
    margin-bottom:28px;
    padding-bottom: 100px;
}
.footer-links-column .logo {
    margin-top:-6px;
}
.footer-links-column h4,
.footer-links-column p {
    margin-left: 21px;
    margin-right: 21px;
    margin-block-end: 16px;
    font-size:14px;
}
.footer-links-column p {
    opacity:0.75;
    display:block;
    overflow:hidden;
    text-overflow:ellipsis;
}
.footer-links-column .logo h1 {
    margin-top:0;
    margin-left:21px;
}
.footer-links-column {
    width:20%;
    float:left;
}
.footer-links-column a {
    text-decoration: none;
}
.footer-links-column a:hover {
    opacity:0.75;
}
.footer-links-column a:active {
    opacity:0.5;
}
.footer-links-column h4 {
    opacity:1;
    margin-top:0;
}
.footer-links-column:nth-of-type(2),
.footer-links-column:nth-of-type(3),
.footer-links-column:nth-of-type(4) {
    white-space: nowrap;
}
@media (max-width:600px) {
    .footer-links-column {
        width:100%;
    }
    .footer-links-column:nth-of-type(2) {
        margin-top:14px;
    }
    .footer-links-column:nth-of-type(3),
    .footer-links-column:nth-of-type(4) {
        width:100%;
        margin-top:-36px;
    }
    .footer-links-column h4 {
        margin-top:56px;
    }
}

html.training_mode .div_generate_button_container {
    display:none;
}
html.training .logo-box,
html.taking-photos .logo-box {
    -webkit-animation: rotating 5s linear infinite;
    -moz-animation: rotating 5s linear infinite;
    -ms-animation: rotating 5s linear infinite;
    -o-animation: rotating 5s linear infinite;
    animation: rotating 5s linear infinite;
}
.logo-box {
    width:30px;
    height:30px;
    margin-right:7px;
    margin-top:-4px;
    vertical-align:middle;
    background: -webkit-linear-gradient(45deg, #680aca, #ff897e);
    background: linear-gradient(60deg,#f79533,#f37055,#ef4e7b,#a166ab,#5073b8,#1098ad,#07b39b,#6fba82);
    -webkit-animation: animatedgradient 6s ease infinite alternate;
    animation: animatedgradient 6s ease infinite alternate;
    background-size: 300% 300%;
    mask-image:url('../assets/logo.svg');
    mask-repeat: no-repeat;
    mask-size: contain;
    -webkit-mask-image:url('../assets/logo.svg');
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-size: contain;
        display:inline-block;
}
