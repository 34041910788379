.info {
    cursor: pointer;
    filter: invert(1);
    display: inline-block;
    width: 12px;
    height: 12px;
    vertical-align: middle;
    background-image: url(../../assets/question.svg?2);
    background-size: cover;
    margin-left: 1px;
    margin-top: -2px;
}

.label {
    font-size: 0.875rem;
    font-weight: 500;
    color: #ffffff;
}

.image-clear-button {
    position: absolute;
    bottom: 8px;
    right: 8px;
    border-radius: 0.25rem;
    background: rgba(255, 0, 0, 0.8);
    border: none;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
  }
  
  .image-clear-button:hover {
    background: rgba(255, 0, 0, 1);
  }

.info:hover {
    opacity: 0.75;
}

.info:active {
    opacity:0.5;
}

.inputDiv {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.manual_description {
    width: 100%;
    background-color: #2a2a2a;
    border: 1px solid #333333;
    border-radius: 0.25rem;
    padding: 0.5rem;
    font-size: 0.875rem;
    min-height: 100px;
}

.manual_prompt {
    width: 100%;
    background-color: #2a2a2a;
    border: 1px solid #333333;
    border-radius: 0.25rem;
    padding: 0.5rem;
    font-size: 0.875rem;
}

.tooltip {
    background:var(--input-background-color);
    position:fixed;
    top:0;
    left:0;
    border:1px solid var(--global-border-color);
    width:300px;
    border-radius:12px;
    filter:drop-shadow(rgba(0, 0, 0, 0.36) 0px 0px 16px) drop-shadow(rgba(0, 0, 0, 0.36) 0px 0px 24px);
    z-index:100;
    margin:14px;
    padding:14px;
    font-size:14px;
    /* display:none; */
    max-width:100vw;
    color:hsl(0 0% 75% / 1);
    overflow:hidden;
}
.tooltip img {
    margin-left: -14px;
    margin-top: -14px;
    width: calc(100% + 14px + 14px);
    object-fit: cover;
    object-position: center;
    height:150px;
    margin-bottom:7px;
}

p.subline {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.5);
    margin-top: -14px;
}

span.label_manual_seed {
    cursor: pointer;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-bottom: 4px;
}

span.label_manual_seed:hover {
    opacity: 0.75;
}

span.label_manual_seed:active {
    opacity: 0.5;
}

.sidebar input[type="checkbox"] {
    width: auto;
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    /* height: 20px;
    width: 20px; */
    border-radius: 12px;
    background: rgb(244, 115, 83);
    cursor: pointer;
    /* margin-top: -6px; Centers the thumb on the track */
    box-shadow: 0 0 4px rgba(0,0,0,0.2);
  }