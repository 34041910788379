
.feature.comparisons {
    text-align:center;
}
.feature .comparison {
    text-align: left;
    width:calc(calc(100% / 3) - (28px * 4));
    display:inline-block;
    overflow:hidden;
    margin:14px;
    border: 1px solid var(--global-border-color);
    border-radius: 18px;
}
.feature .comparison.photo { 
    border: 1px solid #f47353;
    outline: 1px solid #f47353;
 }
.feature .comparison img {
    width:100%;
    height:auto;
    border-radius:12px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 1px solid var(--global-border-color);
}
.feature .comparison .label {
    padding:14px;
    display:block;
}
.feature.comparisons .output_image {
    cursor:zoom-in;
}
.feature.comparisons .output_image:hover {
    opacity:0.75;
}
.feature.comparisons .output_image:active {
    opacity:0.5;
}
@media (max-width:600px) {
    .feature .comparison {
        width:calc(100% - 14px);
    }
    .feature.comparisons {
        margin:14px;
    }
}
