:root {
    --background: #1a1a1a;
    --foreground: #ffffff;
    --primary: #10b981;
    --primary-foreground: #ffffff;
    --muted: #2a2a2a;
    --muted-foreground: #a0a0a0;
    --border: #333333;
    --accent: #34d399;
  }

  .logo-box {
    width:70px;
    height:20px;
    vertical-align:middle;
    background: linear-gradient(to right, var(--primary), var(--accent));
    mask-image:url('/public/logo.svg');
    mask-repeat: no-repeat;
    mask-size: contain;
    -webkit-mask-image:url('/public/logo.svg');
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    display:inline-block;
    margin-top: 2px;
}

.nsfwInfo {
  font-size: small;
}
  
.sidebar {
  width: 320px;
  height: 100vh;
  border-right: 1px solid var(--border);
  background: linear-gradient(to bottom, var(--background), rgba(42, 42, 42, 0.8));
  backdrop-filter: blur(8px);
  display: flex;
  flex-direction: column;
  color: var(--foreground);
}

/* Mobile specific adjustments */
@media (max-width: 768px) {
  .sidebar {
      width: 100%;
      height: 100%;
      position: relative;
      background: var(--background);
  }

  .sidebar-content {
      overflow-y: visible;
  }
}
  
.sidebar-header {
  padding: 1rem;
}
  
  .sidebar-title {
    font-size: 1.5rem;
    font-weight: bold;
    background: linear-gradient(to right, var(--primary), var(--accent));
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  
  .sidebar-content {
    flex: 1;
    overflow-y: auto;
}
  
.sidebar-footer {
  padding: 1rem;
  border-top: 1px solid var(--border);
}
  
  .accordion {
    width: 100%;
  }
  
  .accordion-item {
    border-bottom: 1px solid var(--border);
  }
  
  .accordion-trigger {
    width: 100%;
    padding: 0.75rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: none;
    border: none;
    text-align: left;
    cursor: pointer;
    color: var(--foreground);
    transition: background-color 0.3s;
  }
  
  .accordion-trigger:hover {
    background-color: var(--muted);
  }
  
  .accordion-content {
    padding: 0.75rem 1rem;
    background-color: rgba(42, 42, 42, 0.3);
    color: var(--muted-foreground);
    overflow: visible;
}
  
  .accordion-icon {
    transition: transform 0.2s;
  }
  
  .accordion-item[data-state="open"] .accordion-icon {
    transform: rotate(90deg);
  }

  .user-menu-wrapper[data-state="open"] .accordion-icon {
    transform: rotate(90deg);
  }

  .rotate-90 {
    --tw-rotate: 90deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  
  .preference-inputs {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .preference-input {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .preference-input label {
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--foreground);
  }
  
  .preference-select {
    background-color: var(--muted);
    color: var(--foreground);
    border: 1px solid var(--border);
    border-radius: 0.25rem;
    padding: 0.5rem;
    font-size: 0.875rem;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='%23ffffff' viewBox='0 0 16 16'%3E%3Cpath d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 0.5rem center;
    padding-right: 2rem;
  }
  
  .preference-select:focus {
    outline: none;
    border-color: var(--primary);
    box-shadow: 0 0 0 2px rgba(16, 185, 129, 0.2);
  }
  
  .preference-toggle-wrapper {
    display: flex;
    align-items: center;
  }
  
  .toggle-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 100%;
  }
  
  .toggle-text {
    font-size: 0.875rem;
    color: var(--foreground);
  }
  
  .toggle-checkbox {
    display: none;
  }
  
  .preferences-toggle-switch {
    position: relative;
    width: 40px;
    height: 20px;
    background-color: var(--muted);
    border-radius: 20px;
    transition: background-color 0.3s;
  }
  
  .preferences-toggle-switch::before {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    top: 2px;
    left: 2px;
    background-color: var(--foreground);
    transition: transform 0.3s;
  }
  
  .toggle-checkbox:checked + .preferences-toggle-switch {
    background-color: var(--primary);
  }
  
  .toggle-checkbox:checked + .preferences-toggle-switch::before {
    transform: translateX(20px);
  }
  
  .toggle-checkbox:focus + .preferences-toggle-switch {
    box-shadow: 0 0 0 2px rgba(16, 185, 129, 0.2);
  }
  
  .take-photo-button {
    width: 100%;
    padding: 0.75rem 1rem;
    font-weight: 600;
    color: white;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to right, #10b981, #34d399, #6ee7b7);
  }

  .take-photo-button.disabled {
    background: var(--muted);
    cursor: not-allowed;
  }
  
  .take-photo-button::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.8;
    z-index: 1;
    transition: opacity 0.3s;
  }
  
  .take-photo-button:hover::before {
    opacity: 1;
  }
  
  .take-photo-button span {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
  }
  
  @keyframes gradient-animation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  .take-photo-button::before {
    animation: gradient-animation 3s ease infinite;
    background-size: 200% 200%;
  }
  
  .user-menu-wrapper {
    margin-bottom: 1rem;
  }
  
  .user-menu-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0.75rem 1rem;
    background-color: var(--muted);
    border: none;
    border-radius: 0.25rem;
    color: var(--foreground);
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .user-menu-button:hover {
    background-color: var(--muted-foreground);
  }
  
  .user-menu-button span {
    margin-left: 0.5rem;
    margin-right: auto;
  }
  
  .user-menu {
    margin-top: 0.5rem;
    background-color: var(--background);
    border: 1px solid var(--border);
    border-radius: 0.25rem;
    overflow: hidden;
  }
  
  .user-menu-item {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.75rem 1rem;
    background: none;
    border: none;
    color: var(--foreground);
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .user-menu-item:hover {
    background-color: var(--muted);
  }
  
  .user-menu-item span {
    margin-left: 0.5rem;
  }
  
  .nsfw-message {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(16, 185, 129, 0.9);
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 0.875rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    animation: fadeInOut 3s ease-in-out;
  }
  
  @keyframes fadeInOut {
    0%, 100% { opacity: 0; }
    10%, 90% { opacity: 1; }
  }
  
  .rainbow-border {
    height: 2px;
    background: linear-gradient(
      to right,
      #ff0000,
      #ff7f00,
      #ffff00,
      #00ff00,
      #0000ff,
      #8b00ff
    );
    background-size: 200% 200%;
    animation: rainbow-animation 5s linear infinite;
  }
  
  @keyframes rainbow-animation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  

  /*SELECT MODEL ROW*/
  .select_your_model_current_model_thumbnail {
    width:40px;
    height:40px;
    vertical-align:middle;
    display:inline-block;
    border-radius:12px;
    background:none;
    background-size:cover;
    border:1px solid var(--global-border-color);
    margin-left:7px;
    margin-top:-10px;
    margin-bottom:-10px;
}

.model_samples {
    padding:7px;
    background: #2a2a2a;
    border:1px solid var(--global-border-color);
    border-radius:12px;
    /* max-height:160px; */
    max-height:200px;
    overflow-y:scroll;
    margin-bottom:14px;
    margin-top:-2px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    transition:max-height 0.1s;
    width: 100%;
}
.model_samples .divider {
    margin-top: 7px;
    margin-bottom: 7px;
    margin-left: -7px;
    margin-right: -7px;
    border-top: 1px solid var(--global-border-color);
    margin-top: 14px;
    display: block;
}
.model_samples .divider span {
    text-align:center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 10px;
    display: table;
    margin:0 auto;
    margin-top:-6px;
    background:var(--input-background-color);
    padding-left:7px;
    padding-right:7px;
}
/*.model_samples:focus,
.model_samples:active,
.model_samples:hover {
    max-height:450px;
}*/
select.model_id {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background: #2a2a2a;
    width: 100%;
}
.model_sample {
    border:1px solid var(--global-border-color);
    display:inline-block;
    overflow:hidden;
    position:relative;
    cursor:pointer;
    margin:3.5px;
    width:calc(100% / 3 - 10px);
    border-radius:12px;
}

.model_sample.loading {
    border:4px solid #f47353;
}
.model_sample.new {
    border:4px solid #f47353;
}
.model_sample.active {
    border:4px solid #2bde73;
    box-shadow:0px 0px 10px rgba(43,222,115,0.5);
}
.model_sample:active {
    opacity:0.5;
}
.model_sample_meta {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    font-size:8px;
    position: absolute;
    background: var(--meta-label-background-color);
    color:var(--global-text-color);
    bottom: 0;
    right: 0;
    padding: 3.5px;
    border-bottom-right-radius: 12px;
    border-top-left-radius: 12px;
    padding-left:7px;
    z-index: 1;
}
.model_sample .action-delete-model {
    display:none;
    position: absolute;
    top:3px;
    right:3px;
    z-index:1;
    cursor:pointer;
}
.model_sample:hover .action-delete-model {
    display:block;
}
.model_sample .action-delete-model:hover {
    opacity:0.75;
}
.model_sample .action-delete-model:active {
    opacity:0.5;
}
.model_sample .action-publish-model {
    display:none;
    position: absolute;
    top:1px;
    left:5px;
    z-index:1;
    cursor:pointer;
}
.model_sample:hover .action-publish-model {
    display:block;
}
.model_sample .action-publish-model:hover {
    opacity:0.75;
}
.model_sample .action-publish-model:active {
    opacity:0.5;
}
.model_sample_img {
    object-fit:cover;
    min-height:94px;
    height: fit-content;
}
.model_sample.flux:after {
    content:'flux';
    background:#2bde73;
    color:#000;
    font-weight:bold;
    position:absolute;
    top:0;
    right:0;
    font-size:8px;
    padding:5px;
    border-bottom-left-radius: 6px;
    text-transform: uppercase;
}