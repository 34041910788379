.alert {
    background-color: rgba(52, 211, 153, 0.1);
    border: 1px solid #34d399;
    border-radius: 0.375rem;
    color: #34d399;
    padding: 1rem;
    margin-bottom: 1rem;
  }
  
  .alert-title {
    font-size: 0.875rem;
    font-weight: 600;
    margin-bottom: 0.25rem;
  }
  
  .alert-description {
    font-size: 0.875rem;
  }