.training_examples {
    cursor: pointer;
    border: 1px solid var(--global-border-color);
    border-radius: 0.25rem;
    padding: 14px;
    margin-bottom: 14px;
}

.training_examples p {
    margin-left: 0;
    margin-right: 0;
}

.training_examples img {
    position: relative;
    display: block;
    object-fit: cover;
    height: 98px;
    pointer-events: none;
    width: 100%;
}

.training_examples_scroller {
    white-space: nowrap;
    overflow-x: scroll;
    margin-bottom: 14px;
}

.training_examples_scroller.bad {
    margin-bottom: 0;
}

.training_example {
    border: 1px solid var(--global-border-color);
    width: calc(100% / 3 - 10px);
    margin: 3.5px;
    border-radius: 12px;
    display: inline-block;
    overflow: hidden;
    position: relative;
}

.training_examples:active {
    opacity: 0.5;
}

.training_examples_scroller.good .training_example:after {
    content: '✅';
    position: absolute;
    bottom: 3px;
    right: 3px;
    font-size: 10px;
}

.training_examples_scroller.bad .training_example:after {
    content: '❌';
    position: absolute;
    bottom: 3px;
    right: 3px;
    font-size: 10px;
}

span.label {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 7px;
    display: block;
}

.button.gradient {
    background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
    animation: animatedgradient 6s ease infinite alternate;
    background-size: 300% 300%;
    border: 1px solid rgba(0, 0, 0, 0);
    color: #fff;
}

.div_training_uploader {
    overflow: hidden;
    color: #8c8c8c;
    border: 1px dashed var(--global-border-color);
    background: var(--input-background-color);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 0.25rem;
    cursor: pointer;
    text-align: center;
    position: relative;
    margin-bottom: 14px;
    display: block;
    background: radial-gradient(#424242 0.5px, transparent 0.5px), radial-gradient(#424242 0.5px, #111 0.5px);
    background-size: 20px 20px;
    background-position: 0 0, 10px 10px;
    background-repeat: initial;
    height: 250px;
}

.div_training_uploader .loading_spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.loading_spinner {
    pointer-events: none;
    display: none;
    z-index: 1;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    height: auto;
    text-align: center;
}

.div_training_uploader_center {
    padding: 14px;
    left: 50%;
    top: 50%;
    width: 100%;
    position: absolute;
    transform: translateX(-50%) translateY(-50%);
}

.input_training_uploader {
    display: none;
}

.input_create_model_name {
    background-color: #2a2a2a;
    border: 1px solid #333333;
    border-radius: 0.25rem;
    padding: 0.5rem;
    font-size: 0.875rem;
}

.select_create_model_gender {
    background-color: #2a2a2a;
    border: 1px solid #333333;
    border-radius: 0.25rem;
    padding: 0.5rem;
    font-size: 0.875rem;
}

.inputError {
    border-color: red !important;
}

.ageContainer {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 16px 24px;
}

.ageCheckbox {
    width: 16px;
    height: 16px;
    cursor: pointer;
    accent-color: #10b981;
}

.ageLabel {
    font-size: 14px;
    color: white;
    cursor: pointer;
    user-select: none;
}

.CreateModelButton {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
}