.pricing,
	.testimonials,
	.templates,
	.faq {
		max-width:1200px;
		margin:0 auto;
		margin-bottom:28px;
		margin-top:28px;
	}

    .faq-img {
		float:left;
		clear:both;
		width:50%;
		width:calc(50% - 14px);
		margin-left:14px;
		margin-top:28px;
	}
	.faq-right {
		text-align:left;
		width:50%;
		float:right;
		margin-top:28px;
	}
	@media (max-width:600px) {
		.faq-img {
			display:none;
		}
		.faq-right {
			width:auto;
			float:none;
		}
	}
	.faq h2,
	.faq h3,
	.faq h4,
	.faq p {
		margin:14px;
	}

	.pricing h2,
	.templates h2,
	.feature h2,
	.faq h2 {
		font-size:42px;
	}
	.faq h4 {
		cursor:pointer;
		border-top:1px solid var(--global-border-color);
		padding-top:21px;
		padding-bottom:21px;
		margin-top:0;
		margin-bottom:0;
		position:relative;
		padding-right:28px;
	}
	.faq h4:after {
		content:'+';
		font-size:20px;
		position: absolute;
		right:14px;
		top: 50%;
		transform: translateY(-50%);
		font-weight:400;
		transition: transform .3s ease-out;
	}
	.faq h4.expanded:after {
		transform: translateY(-50%) rotate(-45deg);
		transition: transform .3s ease-out;
	}
	.faq h4:not(.expanded):hover {
		opacity:0.75;
	}
	.faq h4:active {
		opacity:0.5;
	}
	.faq h4 {
		font-weight:800;
		font-size:18px;
		line-height:1.5;
		user-select: none;
	}
	.faq h4:not(.expanded):last-of-type {
		border-bottom:1px solid var(--global-border-color);
	}
	.templates p,
	.feature p,
	.faq p {
		line-height:1.75;
		font-size:18px;
		overflow:hidden;
		margin-top:0;
		margin-bottom:0;
		transition: none;
	}
	.faq p {
		max-height:0px;
	}
	.faq p.expanded {
		max-height:1000px;
		transition: .3s ease;
		padding-bottom:28px;
	}