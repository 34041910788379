.bubble-container {
    border-radius: 0.5rem;
    margin-bottom: 21px;
  }
  
  .button-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
  
  .button-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .bubble-button {
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    transition: all 200ms;
    background-color: #374151;
    color: #e5e7eb;
    border: none;
    cursor: pointer;
  }
  
  .bubble-button:hover {
    background-color: #4b5563;
  }
  
  .bubble-button.selected {
    background: #10b981;
    color: white;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  }
  
  .input-container {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
  
  .input-label {
    font-size: 0.875rem;
    color: #9ca3af;
  }
  
  .input-field {
    padding: 0.25rem 0.75rem;
    border-radius: 0.375rem;
    background-color: #1f2937;
    color: white;
    border: 1px solid #4b5563;
    font-size: 0.875rem;
  }
  
  .input-field:focus {
    outline: none;
    border-color: #3b82f6;
  }
  
  .input-field::placeholder {
    color: #6b7280;
  }

  @keyframes animatedgradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}