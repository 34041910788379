.processing-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: black;
  }
  
  .processing-card {
    background: var(--global-background-color);
    border: 1px solid #10b981;
    border-radius: 12px;
    padding: 21px;
    z-index: 10000;
    font-size: 18px;
    text-align: center;
    width: 80vw;
    max-width: 400px;
    margin: 0;
    color: #10b981;
  }

  .processing-card.error {
    border: 1px solid #ef4444;
  }
  
  .processing-heading {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  .processing-paragraph {
    color: grey;
  }
  
  .success-heading {
    color: #10b981;
  }
  
  .error-heading {
    color: #ef4444;
  }