.modal_alert {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    background: var(--global-background-color);
    border: 1px solid #10b981;
    border-radius: 12px;
    padding: 21px;
    z-index: 10000;
    font-size: 18px;
    text-align: center;
    width: 80vw;
    max-width: 400px;
    margin: 0;
    color: #ff5252;
  }

  .alert_message {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .button_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 1rem;
  }
  
  .modal_alert .button.half {
    display: inline-block;
    padding: 9px;
  }
  
  .button.red.reverse {
    color: #ff5252;
    background: none;
  }
  
  .button.red.reverse:hover {
    color: white;
    background: #ff5252;
  }
  
  .button.red {
    background: #ff5252;
    border-color: #ff5252;
  }
  
  .button.reverse {
    background: none;
    color: var(--global-text-color);
  }
  
  .modal_alert_backdrop {
    cursor: pointer;
    position: fixed;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.75);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: block;
    -webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px);
  }