@media (min-width: 800px) {
    html:not(.guest) .div_output_images {
        /* margin-top: 50px; */
        padding-top: 14px;
    }
}

.div_output_images {
    display: none;
    /* overflow-y: scroll; */
    /* height: 100%; */
}

.div_output_images {
    min-height: 40vh;
    padding-left: 7px;
    padding-right: 7px;
    max-width: none !important;
}