
.testimonials {
    font-size:16px;
    line-height:1.5;
}
.testimonials .stars {
    font-size:28px;
    letter-spacing: 4px;
    margin-left:21px;
}
.testimonials p {
    font-size:16px;
}
.testimonial { 
    text-align:left;
 }
 @media (max-width:600px) {
     .testimonial {
        width:auto;
     }
 }
