
.select_your_model_current_model_thumbnail {
    width:40px;
    height:40px;
    vertical-align:middle;
    display:inline-block;
    border-radius:12px;
    background:none;
    background-size:cover;
    border:1px solid var(--global-border-color);
    margin-left:7px;
    margin-top:-10px;
    margin-bottom:-10px;
}

.model_samples {
    padding:7px;
    background:var(--input-background-color);
    border:1px solid var(--global-border-color);
    border-radius:12px;
    /* max-height:160px; */
    max-height:200px;
    overflow-y:scroll;
    margin-bottom:14px;
    margin-top:-2px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    transition:max-height 0.1s;
}
.model_samples .divider {
    margin-top: 7px;
    margin-bottom: 7px;
    margin-left: -7px;
    margin-right: -7px;
    border-top: 1px solid var(--global-border-color);
    margin-top: 14px;
    display: block;
}
.model_samples .divider span {
    text-align:center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 10px;
    display: table;
    margin:0 auto;
    margin-top:-6px;
    background:var(--input-background-color);
    padding-left:7px;
    padding-right:7px;
}
/*.model_samples:focus,
.model_samples:active,
.model_samples:hover {
    max-height:450px;
}*/
select.model_id {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.model_sample {
    border:1px solid var(--global-border-color);
    display:inline-block;
    overflow:hidden;
    position:relative;
    cursor:pointer;
    margin:3.5px;
    width:calc(100% / 3 - 10px);
    border-radius:12px;
    /*border:4px solid rgba(0,0,0,0); */
}

.model_sample.loading {
    border:4px solid #f47353;
}
.model_sample.new {
    border:4px solid #f47353;
}
.model_sample.active {
    border:4px solid #2bde73;
    box-shadow:0px 0px 10px rgba(43,222,115,0.5);
}
.model_sample:active {
    opacity:0.5;
}
.model_sample_meta {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    font-size:8px;
    position: absolute;
    background: var(--meta-label-background-color);
    color:var(--global-text-color);
    bottom: 0;
    right: 0;
    padding: 3.5px;
    border-bottom-right-radius: 12px;
    border-top-left-radius: 12px;
    padding-left:7px;
    z-index: 1;
}
.model_sample .action-delete-model {
    display:none;
    position: absolute;
    top:3px;
    right:3px;
    z-index:1;
    cursor:pointer;
}
.model_sample:hover .action-delete-model {
    display:block;
}
.model_sample .action-delete-model:hover {
    opacity:0.75;
}
.model_sample .action-delete-model:active {
    opacity:0.5;
}
.model_sample .action-publish-model {
    display:none;
    position: absolute;
    top:1px;
    left:5px;
    z-index:1;
    cursor:pointer;
}
.model_sample:hover .action-publish-model {
    display:block;
}
.model_sample .action-publish-model:hover {
    opacity:0.75;
}
.model_sample .action-publish-model:active {
    opacity:0.5;
}
.model_sample_img {
    object-fit:cover;
    min-height:94px;
    height: fit-content;
}
.model_sample.flux:after {
    content:'flux';
    background:#2bde73;
    color:#000;
    font-weight:bold;
    position:absolute;
    top:0;
    right:0;
    font-size:8px;
    padding:5px;
    border-bottom-left-radius: 6px;
    text-transform: uppercase;
}