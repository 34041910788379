.netflixContainer {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    margin-bottom: 56px;
    background-color: #111;
    z-index: 1;
}

.netflixContainerPerspective {
    perspective: 500px;
    height: 100%;
    position: relative;
}

.netflixContainerBackground {
    height: 100%;
    transform: rotateX(365deg) rotateY(352deg) rotateZ(10deg) translateX(1300px);
    transform-style: preserve-3d;
    animation: netflixMove 180s linear infinite alternate;
    will-change: transform;
}

.netflixBox {
    margin: 5px 0;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    flex-wrap: nowrap;
    flex-grow: 1;
    transform: translateX(100px) translateY(-200px);
}

.netflixThumbnail {
    transform-style: preserve-3d;
    transform: rotateX(20deg) rotateY(0deg) skewX(335deg);
    min-width: 125px;
    min-height: 187px;
    display: inline-block;
    background-position: center;
    background-size: cover;
    margin: 7px;
    border-radius: 12px;
    opacity: 0;
    animation: fadeIn 0.3s ease-out forwards;
    will-change: opacity;
    contain: content;
}

@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
}

.netflixGradient {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    background: linear-gradient(to bottom, rgba(0,0,0,0.8) 15%, rgba(0,0,0,0.80) 50%, rgba(0,0,0,0.95) 70%, rgba(0,0,0,0.6) 95%, rgba(0,0,0,1) 100%);
    opacity: 0.9;
}

@keyframes netflixMove {
    to {
        transform: rotateX(365deg) rotateY(352deg) rotateZ(10deg) translateX(-190px);
    }
}