
.hero_footer_quotes {
    position: absolute;
    bottom: 0;
    z-index: 10;
    max-width:100%;
    width:calc(1200px - 14px - 14px);
    left:50%;
    transform:translateX(-50%);
    top:100%;
}
.testimonial,
.hero_footer_quote {
    box-shadow:5px 5px 5px rgba(0,0,0,0.25);
    padding:14px;
    float:left;
    width:calc(100% / 3 - 21px - 21px);
/*				border:1px solid var(--global-border-color);*/
/*				border:2px solid #161616;*/
/*				border:2px solid #fff;*/
    background:#fff;
    color:#000;

/*				background:#000;*/
/*				background:rgba(0,0,0,0.75);*/
/*				background:rgb(50 50 50 / 50%);*/
/*				color:#fff;*/

    border-radius:12px;
    margin:21px;
/*				margin-top:7px;*/
    text-align: center;
/*				background: linear-gradient(60deg,#f79533,#f37055,#ef4e7b,#a166ab,#5073b8,#1098ad,#07b39b,#6fba82);*/
/*				animation: animatedgradient 6s ease infinite alternate;*/
    background-size: 300% 300%;
    border:2px solid #f47353;
}
.hero_footer_quote p {
    margin:0;
    font-style:bold;
    font-weight: 500;
    font-size: 18px;
    line-height: 160%;
    margin-bottom:14px;
}
.hero_footer_quote img {
    pointer-events: none;
    height:25px;
/*				filter:invert(1) saturate(0) brightness(2);*/
/*				mix-blend-mode: lighten;*/
    vertical-align: middle;
    margin-top: 14px;
    margin-bottom: 14px;
}
.hero_footer_quote {
    white-space: initial;
}
.hero_footer_quotes {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.hero_footer_quotes::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}
@media (min-width:600px) {
    .hero_footer_quotes .quote_1 {
        transform: perspective(500px) rotateY(14deg);
        transition: transform 1s ease 0s;
    }
    .hero_footer_quotes .quote_2 {
        transform: perspective(500px) translateZ(-41px);
        transition: transform 1s ease 0s;
    }
    .hero_footer_quotes .quote_3 {
        transform: perspective(500px) rotateY(-14deg);
        transition: transform 1s ease 0s;
    }
    .hero_footer_quotes .hero_footer_quote:hover {
        transform:none;
    }
    .feature-left .feature-3d {

    }
}
@media (max-width:600px) {
    .hero_footer_quotes {
        width:auto;
        left:initial;
        transform:none;
        width:calc(100% - 21px - 21px);
    }
    .testimonial,
    .hero_footer_quote {
        display:block;
        float:none;
        transform:none;
        width:100%;
    }
}
