@media (min-width:1100px) {
    html.guest .div_output_image:nth-child(odd) {
        margin-top:calc(-19vw);
    }
    html.guest .div_output_image:nth-child(even) {
        margin-top:0;
    }
    /*html.logged-in .div_output_image:nth-child(odd) {
        margin-top:-50px
    }
    html.logged-in .div_output_image:nth-child(even) {
        margin-top:0;
    }*/
}

@media (min-width:1400px) {
    /*html.logged-in .div_output_image {
        margin-top:0;
    }
    html.logged-in .div_output_image:nth-child(3n-1) {
        margin-top:-200px
    }*/
    html.guest .div_output_image:nth-child(odd) {
        margin-top:calc(-10vw);
    }
    html.guest .div_output_image:nth-child(even) {
        margin-top:0;
    }
}

html.guest .div_output_images {
    padding-top:14px;
/*		background:#fff;*/
}

.div_output_image img,
	.div_output_image video {
		width:100%;
		height:auto;
		display:block;
		pointer-events: none; /* avoid users saving low res CDN'd image */
	}

.div_output_image.camera {
    cursor:zoom-in;
}

.div_output_image {
    
    min-height:100px;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select:none;
    vertical-align: top;
    overflow: hidden;
    position: relative;
    display: inline-block;
/*			margin: 14px;*/
        margin: 7px;
    margin-top: 0;
/*		margin-bottom: 28px;*/
    margin-bottom: 14px;
    height: auto;
    border-radius: 12px;
    border: 1px solid var(--photo-border-color);
/*		width: calc(100% / 4 - 31px);*/
    width: calc(100% / 4 - 14px);
}

a:hover {
    opacity: 0.3;
}
a:active {
    opacity: 0.5;
}
.div_output_images {
    min-height:40vh;
    padding-left:7px;
    padding-right:7px;
    max-width:none !important;
}

.div_output_image.remove_background img.output_image {
    background-image: repeating-linear-gradient(
        45deg,
        #c8c8c8 25%,
        transparent 25%,
        transparent 75%,
        #c8c8c8 75%,
        #c8c8c8
        ),
        repeating-linear-gradient(
        45deg,
        #c8c8c8 25%,
        #fff 25%,
        #fff 75%,
        #c8c8c8 75%,
        #c8c8c8
        );
    background-position:
        0 0,
        8px 8px;
    background-size: 16px 16px;
}
/* html.template .div_output_image {
    width: calc(100% / 4 - 7px - 7px);
} */
/* @media (min-width:1400px) {
    .div_output_image.landscape {
        width: calc(100% / 3 - 14px);
    }
} */
@media (max-width: 1400px) {
    html:not(.guest) .div_pose,
    html:not(.guest) .div_template,
    html:not(.guest) .div_output_image {
/*			width: calc(100% / 3 - 14px - 14px);*/
        width: calc(100% / 3 - 7px - 7px);
    }
    html.guest .div_output_image,
    html.guest .div_template,
    html.template .div_output_image {
        width: calc(100% / 4 - 7px - 7px);
    }
}

@media (max-width:1400px) {
    .div_output_image.landscape {
        width: calc(100% / 2 - 14px);
    }
}
@media (max-width:1100px) {
    .div_output_image.landscape {
        width: calc(100% - 14px);
    }
}

.div_output_image_container {
    border-radius:12px;
    overflow:hidden;
}

@media (min-width:600px) {
    /* make image clickable on desktop to view but not on mobile cause no viewer there */
    .div_output_image.finished.camera{
        cursor:zoom-in;
    }
    .div_output_image.nsfw {
            cursor:not-allowed;
    }
}

html.guest .div_output_image,
html.template .div_output_image {
    width: calc(100% / 6 - 7px - 7px);
}

html.demo .canny,
html.demo .runtime,
html.demo .sidebar .alert,
html.demo .header-link:not(.credits),
html.demo .trademark .mrr,
html.demo .div_generate_button_container .subline {
    display:none;
}

.trademark .mrr {
    display:none;
}