.image_viewer, .image_viewer img, .image_viewer video {
    max-width: 90vw;
    max-height: 90vh;
}

.image_viewer_arrow_right {
    position: absolute;
    top: 50%;
    right: 14px;
    width: 30px;
    transform: translateY(-50%);
    z-index: 10;
    cursor: pointer;
}

.image_viewer_arrow_left {
    position: absolute;
    top: 50%;
    left: 14px;
    width: 30px;
    transform: translateY(-50%);
    z-index: 10;
    cursor: pointer;
}

.image_viewer {
    overflow: hidden;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: none;
    /* border-radius: 12px; */
    /* width: 832px; */
    width: auto;
    height: auto;
    transform: translateX(-50%) translateY(-50%);
    position: fixed;
    z-index: 102;
    left: 50%;
    top: 50%;
    object-fit: cover;
    background: #161616;
    /* border: 1px solid rgba(255, 255, 255, 0.2); */
    /* background: #fff; */
    /* padding: 7px; */
    /* box-shadow: 0 0 0 1px var(--global-border-color), 0 2px 4px 0 rgb(0 0 0 / 7%), 0 1px 1.5px 0 rgb(0 0 0 / 5%); */
    background: radial-gradient(#424242 0.5px, transparent 0.5px), radial-gradient(#424242 0.5px, #161616 0.5px);
    background-size: 20px 20px;
    background-position: 0 0, 10px 10px;
    background-repeat: initial;
}

.image_viewer_backdrop {
    /* cursor: zoom-out; */
    cursor: default;
}

.div_stripe_modal_backdrop, .div_login_modal_backdrop, .image_viewer_backdrop {
    cursor: pointer;
    position: fixed;
    z-index: 100;
    background: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: none;
    background: rgba(0, 0, 0, 0.75);
    backdrop-filter: blur(12px);
}