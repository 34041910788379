.photo_search {
    width: calc(100% - 14px - 14px);
    margin: 14px;
    margin-bottom: 0;
}

a:hover {
    opacity: 1 !important;
    cursor: pointer;
}

.noImagesText {
    font-size: 1.5em;
    color: var(--global-text-color);
    margin: 0;
    padding: 0;
    text-align: center;
    font-weight: 300;
}

.filterContainer {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 14px;
}