@media (min-width: 800px) {
    .section.main {
        width: calc(100% - var(--left-sidebar-width));
    }
}

@media (min-width: 800px) {
    .sidebar, .section.main {
        position: fixed;
        overflow-x: hidden;
    }
}

@media (max-width: 800px) {
    .section.main {
        border-top: 1px solid var(--global-border-color);
    }
}

@media (max-width: 800px) {
    .main, .right, .sidebar {
        display: block;
        width: auto;
    }
}

@media (min-width: 800px) {
    .section.main {
        height: calc(100%);
    }
}

@media (min-width: 800px) {
    .section.main {
        right: 0;
    }
}

.main {
    margin-bottom: 28px;
}