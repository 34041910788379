.folder-drawer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 1rem;
    background-color: var(--background);
    border-bottom: 1px solid var(--border);
  }
  
  .drawer-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    transition: color 0.3s ease;
    gap: 0.5rem;
  }
  
  .drawer-item:hover {
    color: var(--primary);
  }

  .drawer-item.active {
    color: var(--primary);
  }
  
  .drawer-icon {
    width: 24px;
    height: 24px;
  }
  
  .drawer-label {
    font-size: 0.875rem;
    font-weight: 500;
  }