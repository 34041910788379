
.hero {
    position: relative;
/*			height:450px;*/
    height:800px;
    background-size:cover;
    background-position: 0 -25px;
/*			padding-top:var(--header-height);*/
/*			margin-bottom:-28px;*/
}
@media (max-width:600px) {
    .hero {
        margin-bottom:0;
    }
}
.hero .cta-box p {
    margin:14px;
}
.hero .text {
    position:absolute;
    left:21px;
    transform:translateY(-50%);
    top:50%;
    max-width:calc(100% - 350px - 21px - 21px);
    width:100%;
    text-shadow:1px 1px 1px rgba(0,0,0,.25),2px 2px 1px rgba(0,0,0,.25);
    z-index:2;
}
.hero .cta-box {
    position:absolute;
    right:21px;
    transform:translateY(-50%);
    top:50%;
    max-width:350px;
    width:100%;
    background:var(--sidebar-background-color);
    border-radius:12px;
    padding:7px;
    border:1px solid var(--global-border-color);
    z-index:5;
}
.hero .cta-box.floating {
    bottom: 0;
    position: fixed;
    z-index: 100;
    top: auto;
    transform: none;
    left: 0;
    width: 100%;
    max-width: none;
    border-radius: 0;
    border-bottom: none;
    border-left: none;
    border-right: none;
    margin:0;
}
.hero .cta-box.floating .action-login-with-google {
    display:none;
}
.action-login-with-google.disabled {
    background: none;
    color: black;
    border: 1px solid #fff;
}
.hero .cta-box.floating .already_have_account_text {
    display:none;
}
@media (min-width:800px) {
    .hero .cta-box.floating input {
        width:calc(75% - 14px);
        float:left;
        margin:7px;
    }
    .hero .cta-box.floating .button {
        width:calc(25% - 14px);
        margin:7px;
        float:right;
    }
}
.header .button {
    background: -webkit-linear-gradient(45deg, #680aca, #ff897e);
    background: linear-gradient(60deg,#f79533,#f37055,#ef4e7b,#a166ab,#5073b8,#1098ad,#07b39b,#6fba82);
    -webkit-animation: animatedgradient 6s ease infinite alternate;
    animation: animatedgradient 6s ease infinite alternate;
    background-size: 300% 300%;
    border: 1px solid rgba(0,0,0,0);
    color:#fff;
}
input, select, textarea, .button {
    padding: 14px;
    border-radius: 12px;
    border: 1px solid var(--global-border-color);
    font-size: 16px;
    background: var(--input-background-color);
}
.footer-cta-box .button,
.hero .cta-box .button.action-confirm-email {
    margin-bottom:14px;
    background: -webkit-linear-gradient(45deg, #680aca, #ff897e);
    background: linear-gradient(60deg,#f79533,#f37055,#ef4e7b,#a166ab,#5073b8,#1098ad,#07b39b,#6fba82);
    -webkit-animation: animatedgradient 6s ease infinite alternate;
    animation: animatedgradient 6s ease infinite alternate;
    background-size: 300% 300%;
    border: 1px solid rgba(0,0,0,0);
    color:#fff;
    padding-top:14px;
    padding-bottom:14px;
}
.hero .cta-box .or-wrap {
    width:calc(100% - 14px - 14px);
    margin-left:14px;
    margin-top:-7px;
    margin-bottom:7px;
}

.hero .cta-box.floating .or-text,
.hero .cta-box.floating .or-line {
    display:none;
}
.hero .cta-box .or-text {
    vertical-align:middle;
    display:inline-block;
    width:10%;
    font-size:12px;
    color:var(--global-border-color);
    text-align:center;
}
.hero .cta-box .or-line {
    vertical-align:middle;
    display:inline-block;
    background:var(--global-border-color);
    width:calc(43%);
    height:1px;
}
.hero .cta-box .button {
    margin-left:14px;
    margin-top:0;
    width:calc(100% - 14px - 14px);;
}
.hero .cta-box .button.action-confirm-email:hover {
    background: none;
    color: var(--global-text-color);
    border: 1px solid #fff;
}
.button.disabled {
    filter: saturate(0);
    animation:none;
    cursor:wait;
    pointer-events:none;
}
.button {
    text-decoration: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    background: var(--global-text-color);
    border: 1px solid var(--global-border-color);
    font-weight: bold;
    border-radius: 12px;
    display: inline-block;
    margin: 0px;
    padding: 9px;
    cursor: pointer;
    font-size: 16px;
    text-align: center;
    color: var(--global-background-color);
}
.button:hover {
    background: none;
    color: var(--global-text-color);
}
.button:active {
    opacity: 0.5;
}
.button.red {
    background:#ff5252;
    border-color:#ff5252;;
}
.button.red.reverse:hover {
    background:#ff5252;
    border-color:#ff5252;
}
.button.red.reverse {
    color:#ff5252;;
    background:none;
}
.button.red:hover {
    color:#ff5252;;
    background:none;
}
.button.orange {
    background:#ffc924;
    border-color:#ffc924;
}
.button.orange:hover {
    color:#ffc924;
    background:none;
}
.button.green {
    background:#2bde73;
    border-color:#2bde73;;
}
.button.green:hover {
    color:#2bde73;;
    background:none;
}
.button.reverse {
    background: none;
    color: var(--global-text-color);
}
.button.reverse:hover {
    background: var(--global-text-color);
    color: var(--global-background-color);
}
.hero .cta-box input {
    background:#fff;
    color:#000;
    margin:14px;
    width:calc(100% - 14px - 14px);
    margin-bottom:7px;
}
.hero .cta-box input:focus {
    border:4px solid #f47353;
}
.hero h1 {
    text-transform: initial;
    font-size:56px;
    margin-bottom:14px;
    margin-top:0;
    line-height:1;
}
@media (max-width:600px) {
    .hero h1 {
        font-size:36px;
    }
}
.hero h2 {
    font-weight:600;
    margin-top:0;
    font-size:28px;
    margin-bottom:14px;
    padding-right:56px;
/*			line-height:1;*/
}
.hero h3 {
    font-weight:500;
    margin-top:0;
    font-size:21px;
    margin-bottom:14px;
    padding-right:56px;
/*			line-height:1;*/
}
.hero p {
    margin-left: 21px;
    margin-right: 21px;
}
.hero-width-container {
    /* keeps hero to max width and centered */
    max-width:1200px;
    margin:0 auto;
    position: relative;
    height:100%;
}
.hero .wave {
    position: absolute;
    bottom: -50px;
    left: -10px;
    z-index: 3;
    width: 105vw;
/*			fill:var(--header-background-color);*/
    fill:var(--global-background-color);
}
@media (max-width:1200px) {
    .hero .text p {
        display:none;
    }
}
@media (max-width:1200px) {
    .hero h2 {
        font-size:18px;
    }
}
@media (max-width:1000px) {
    .hero_width_container {
        height:auto;
    }
    .hero h3 {
        font-weight:400;
    }
    .hero .text {
/*				display:none;*/
        width:initial;
        max-width:initial;
        position:relative;
        top:initial;
        left:initial;
        right:initial;
        transform:initial;
        padding-top:28px;
        text-align: center;
        text-align:left;
    }
    .hero .cta-box {
        /*right:0;
        margin:0 auto;
        left:50%;
        transform:translateX(-50%) translateY(-50%);
        max-width:calc(100% - 21px - 21px);*/
        width:initial;
        max-width:initial;
        position:relative;
        top:initial;
        left:initial;
        right:initial;
        transform:initial;
        margin:21px;
        width:auto;
    }
    html.guest .hero {
        height:auto !important;
    }
    .hero .cta-box .cta-box-header,
    .hero .cta-box .cta-box-subtext {
        display:none;
    }
    .hero_footer_logos {
        position:relative !important;
    }
    .hero h1,
    .hero h2,
    .hero h3 {
        padding:0;
        padding:0;
    }
    .hero h2 {
        font-size:18px;
    }
}
html.guest .hero .cta-box .cta-box-header,
html.guest .hero .cta-box .cta-box-subtext,
html.template .hero .cta-box .cta-box-header,
html.template .hero .cta-box .cta-box-subtext {
    display:none;
}
@media (max-height:500px) {
    .hero .cta-box p {
        display:none;
    }
    .hero .cta-box p:first-of-type {
        display:block;
    }
    .hero .cta-box p:last-of-type {
        display:block;
    }
    .hero_footer_logos {
        display:none;
    }
}
.hero .benefit {
    padding-top:14px;
}
@media (max-height:800px) {
    /* hide some benefits to make sure that the sign up cta email box is above the fold */
    .hero h1 {
        margin-bottom:0;
    }
    .hero .sub_sub_slogan,
    .hero .benefit {
        display:none;
    }
    .hero .benefit:nth-of-type(2),
    .hero .benefit:nth-of-type(3),
    .hero .benefit:nth-of-type(4) {
        display:block;
    }
}


div.laurel img.laurel-img {
    width:185px;
}
div.laurel {
    margin-left:21px;
    display: table !important;
    position:relative;
    filter:invert(1);
    margin-bottom:14px;
}
.laurel-text {
    position:absolute;
    width:100%;
    text-align: center;
    top:12px;
    color:#000;
    font-size:13px;
    font-weight:bold;
    text-shadow:none;
}
.laurel-stars {
    position:absolute;
    width:100%;
    text-align: center;
    top:29px;
}
.laurel-stars img {
    width:13px;
    margin-left:2px;
    margin-right:2px;
}
@media (max-width:1000px) {
    div.laurel {
        margin:0 auto !important;
    }
}

.hero_footer_logos {
    white-space: nowrap;
    overflow-x:scroll;
    overflow-y:hidden;
    text-align: center;
    position:absolute;
    bottom:0;
    left:0;
    z-index:10;
    width:100%;
    padding:14px;
}
.hero_footer_logos {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.hero_footer_logos::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}
.hero_footer_logos img {
    pointer-events: none;
    margin-right: 14px;
    height:25px;
    filter:invert(1) saturate(0) brightness(2);
    mix-blend-mode: lighten;
    vertical-align: middle;
}