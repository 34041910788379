html, body {
    padding: 0;
    margin: 0;
    overflow-x:hidden;
}

body {
    margin: 0;
    padding: 0;
    /*padding-bottom: 500px;*/
    background: var(--global-background-color);
    color: var(--global-text-color);
    text-align: left;
    font-family: -apple-system,"system-ui","Segoe UI",sans-serif;
    overflow-x: hidden;
}

:root.dark {
    --meta-label-background-color:rgba(0,0,0,0.5);
    --global-background-color: #111;
    --sidebar-background-color:#0d0d0d;
    --header-background-color:#000;
    --input-background-color:#161616;
    --hover-background-color:#3f3f3f;
    --global-text-color: #fff;
    --global-link-color:#fff;
    --global-border-color: #343434;
    --global-text-color:#fff;
    --photo-border-color:#343434;
    --copycat-gallery-background:rgba(0,0,0,0.75);
    --take-photo-container-height:82px;
}
:root {
    --bold:bold;
    --meta-label-background-color:rgba(255,255,255,0.5);
    --loading-spinner-color: #fff;
/*		--copycat-gallery-height: 135px;*/
    --copycat-gallery-height: 0px;
    --header-height: 60px;
    --left-sidebar-width: 320px;
    --highlight-background-color:#fff38f;
    --global-background-color: #dfdfdf;
    --sidebar-background-color:#fff;
    --header-background-color:#fff;
    --input-background-color:#fff;
    --global-text-color: #1c1c1c;
    --global-link-color: #1c1c1c;
    --global-border-color: #dcdddd;
    --take-photo-container-height:82px;
    --global-text-color:#000;
    --photo-border-color:#dddddd;
    --copycat-gallery-background:rgba(255,255,255,0.75);
}

body,input,select,textarea,a {
    color: var(--global-text-color);
}
* {
    box-sizing: border-box;
}
body,input,select,textarea {
    font-family: monospace;
    font-family: -apple-system,"system-ui","Segoe UI",sans-serif;
    background: #111;
}
textarea.manual_negative_prompt:focus,
textarea.manual_negative_prompt:active,
textarea.manual_negative_prompt.active {
    min-height:125px;
}
textarea.manual_prompt:focus,
textarea.manual_prompt:active,
textarea.manual_prompt.active {
    min-height:110px;
}

input.active,select.active,
input:focus,select:focus,textarea:focus {
    outline: 2px solid #10b981;
    box-shadow: 0 0 0 2px rgba(16, 185, 129, 0.2);
}
select {
    appearance:none;
}
input[type="range"] {
    -webkit-appearance: none; /* Remove default appearance */
    width: 100%;
    outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 8px;
    background: transparent;
    border-radius: 5px;
}
input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    opacity:1;
    width: 30px;
    height: 36px;
    background: #f47353;
    margin-top: -14px;
    margin-left: -14px;
}
input[type="range"]::-moz-range-track {
    background: #f47353;
    border-radius: 5px;
}
input[type="range"]::-moz-range-thumb {
    opacity:0;
    width: 20px;
    height: 20px;
    background: #f47353;
    border-radius: 50%;
}

html.dark ::-webkit-scrollbar {
    height: 12px;
    width: 12px;
    background: #2c2c2c;
}

html.dark ::-webkit-scrollbar-corner {
    background: #2c2c2c;
}

html.dark ::-webkit-scrollbar-thumb {
    background: #6b6b6c;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    -webkit-border-radius: 1ex;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}

.loading_spinner {
    height:auto;
    text-align: center;
    transform: translateX(-50%);
    left: 50%;
    position: absolute;
}
.loading_spinner .loading_spinner_svg {
    width:3em;
    height:3em;
}

.div_clothing_uploader .loading_spinner,
.div_copycat_uploader .loading_spinner,
.div_training_uploader .loading_spinner,
.div_output_image .loading_spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.loading_spinner_circle {
    stroke-width: 6;
    stroke-miterlimit: 10;
}

.loading_spinner_circle--spinner {
    stroke-dasharray: 1,220;
    stroke-dashoffset: 0;
    -webkit-animation: loading_spinner_dash 1.5s ease-in-out infinite,color 3s ease-in-out infinite;
    animation: loading_spinner_dash 1.5s ease-in-out infinite,color 3s ease-in-out infinite;
    stroke-linecap: square;
    stroke:var(--loading-spinner-color);
}

.loading_spinner_circle {
    stroke-width: 6;
    stroke-miterlimit: 10;
}


.loading_spinner .loading_spinner_circle {
    stroke:#111 !important;
}

.loading_spinner .loading_spinner_circle--spinner {
    stroke:#fff !important;
}

.image_loading_spinner {
    height: 20%;
    width: 20%;
}

.model_loading_spinner {
    height: 20%;
    width: 20%;
}

.loading_spinner_svg {
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: loading_spinner_rotate;
    animation-name: loading_spinner_rotate;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}
.loading_spinner .loading_message {
    pointer-events:none;
    margin:0 auto;
    width:100%;
    display:none;
    max-width:95vw;
    pointer-events:none;
    background:none;
    color:var(--global-text-color);
    padding:1em;
    padding:7px;
    padding-left:14px;
    padding-right:14px;
    font-weight:800;
    border-radius:12px;
    border:none;
}


.loading_spinner.main {
    z-index:201;
}
.loading_spinner {
    pointer-events:none;
    display:none;
    z-index:1;
    position:fixed;
    top:50%;
    left:50%;
    transform:translateX(-50%) translateY(-50%);
}
.loading_spinner .loading_message {
    font-variant-numeric: tabular-nums;
    pointer-events:none;
    margin:0 auto;
    width:100%;
    max-width:95vw;
    pointer-events:none;
    color:var(--global-text-color);
    padding:1em;
    padding:7px;
    padding-left:14px;
    padding-right:14px;
    font-weight:800;
    border-radius:12px;
    display:none;
    color: #fff;
}


@keyframes loading_spinner_rotate {
    100% {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn);
        color: transparent;
    }
}

@keyframes loading_spinner_dash {
    0% {
        stroke-dasharray: 1,220;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 97.9,220;
        stroke-dashoffset: -38.5;
    }
    100% {
        stroke-dasharray: 97.9,220;
        stroke-dashoffset: -136.4;
    }
}

@keyframes loading_spinner_color {
    0% {
        stroke: var(--loading-spinner-color);
    }
    50% {
        stroke: var(--loading-spinner-color);
    }
    100% {
        stroke: var(--loading-spinner-color);
    }
}