.genericButton {
    text-decoration: none;
    background: var(--global-text-color);
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 0.25rem;
    display: flex;
    justify-content: center;
    font-weight: 600;
    margin: 0px;
    padding: 9px;
    cursor: pointer;
    text-align: center;
    color: var(--global-background-color);
    background: linear-gradient(to right, #10b981, #34d399, #6ee7b7);
    animation: animatedgradient 6s ease infinite alternate;
    background-size: 300% 300%;
    color: white;
}

.genericButton.no-icon {
  padding-left: 15px;
  padding-right: 15px;
}

.genericButton.disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  
  .button-content {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .button-icon {
    display: flex;
    align-items: center;
  }