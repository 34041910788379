
.landing-main-top-gradient {
    pointer-events: none;
    width:100%;
    height:350px;
    position:absolute;
    left:0;
    /*top:-1px;*/
    z-index:2;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,7db9e8+100&1+0,0+100 */
    background: -moz-linear-gradient(top,  rgba(0,0,0,1) 15%, rgba(125,185,232,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(0,0,0,1) 15%,rgba(125,185,232,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(0,0,0,1) 15%,rgba(125,185,232,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    background: -moz-linear-gradient(top,  rgba(17,17,17,1) 15%, rgba(125,185,232,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(17,17,17,1) 15%,rgba(125,185,232,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(17,17,17,1) 15%,rgba(125,185,232,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#007db9e8',GradientType=0 ); /* IE6-9 */
    opacity:1;
}

html.guest .section.main {
    overflow: initial !important;
    height: auto;
}

html.guest .section.main, html.template .section.main {
    width: 100% !important;
    position: relative !important;
}

@media (min-width: 800px) {
    .section.main {
        width: calc(100% - var(--left-sidebar-width));
    }
}

@media (min-width: 800px) {
    .sidebar, .section.main {
        position: absolute;
        overflow-x: hidden;
    }
}

@media (min-width: 800px) {
    .section.main {
        height: calc(100% - var(--header-height));
    }
}

@media (min-width: 800px) {
    .section.main {
        right: 0;
        width: calc(100% - calc(var(--left-sidebar-width) + 21px + 21px + 21px + 21px));
        /* padding-bottom: 300px; */
    }
}

@media (min-width: 800px) {
    .section.main {
        overflow-y: scroll;
    }
}

.main {
    margin-bottom: 28px;
}