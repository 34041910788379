
.footer-cta-box {
    background:#fff;
    color:#000;
    border-radius:12px;
    width:100%;
    max-width:calc(1200px - 21px);
    margin:14px auto;
    margin-top:56px;
    margin-bottom:28px;
    position:relative;
    overflow:hidden;
    min-height:500px;
}
.footer-cta-box a {
    color:#000;
}
.footer-cta-box img {
    background:rgba(0,0,0,0.1);
    position:absolute;
    top:0;
    right:0;
    width:33.333%;
    height:100%;
    object-fit:cover;
    object-position: center;
/*			object-position: top;*/
}
.footer-cta-box h4 {
    font-size:42px;
    line-height:1;
    margin:0;
    margin-bottom:14px;
}
.footer-cta-box p {
    font-size:18px;
    line-height:1.5;
    margin:0;
    margin-bottom:14px;
}
.footer-cta-box .button:hover {
    border:1px solid #f47353;
    color:#f47353;
    background:none;
}
.footer-cta-box-left {
    padding:42px;
    width:66.6666%;
    transform: translateY(-50%);
    top: 50%;
    position: absolute;
}
@media (max-width:1200px) {
    .footer-cta-box {
        border-radius: 0;
    }
}
@media (max-width:600px) {
    .footer-cta-box-left {
        width:auto;
        position:relative;
        transform:none;
        top:auto;
    }
    .footer-cta-box img {
        position:relative;
        width:100%;
        height:90vw;
        object-fit:cover;
        display:block;
    }
}
