
.detail img {
    width:calc(100% / 4 - calc(14px * 4));
    margin:7px;
    border-radius:12px;
    object-fit:cover;
}
@media (max-width:600px) {
    .detail img {
        width:calc(100% / 2 - calc(14px * 2));
        margin:7px;
        border-radius:12px;
    }
}
