

.div_template {
        padding:28px;
        cursor:pointer;
        background:var(--input-background-color);
/*				float:left;*/
        min-height:325px;
    }
    .templates a.div_template {
        text-decoration: none;
        margin:5px;
    }
    .div_template.active,
    .div_template:focus,
    .div_template:active,
    .div_template:hover {
        border:1px solid #10b981;
    }
    .div_template:active {
        opacity:0.5;
    }
    .div_template_icon {
        border-radius:100%;
        text-align:center;
        font-size:36px;
        padding:14px;
        background:var(--global-background-color);
        border:1px solid var(--global-border-color);
        display:table;
        width:77.5px;
        height:64px;
    }
    .div_template_name {
        font-size:21px;
        font-weight:bold;
        margin-top:14px;
    }
    .div_template_description {
        margin-top:14px;
        font-size:18px;
        opacity:0.75;
    }
    .div_times_used_count,
    .div_template_count {
        margin-top:14px;
        text-transform: uppercase;
        background: #f47353;
        color: #fff;
        
        background: #2bde73;
        color: #161616;

        padding: 3.5px;
        padding-left: 7px;
        padding-right: 7px;
        border-radius: 12px;
        font-size: 12px;
        font-weight: var(--bold);
        vertical-align: middle;
        margin-left: 0;
        display: inline-block;
        line-height: 1;
    }
    .div_times_used_count {
        background:#f47353;	
    }
    .div_template_samples img {
        border-radius:6px;
        margin:3.5px;
        width:calc(50% - 3.5px - 3.5px);
    }

    @media (max-width: 1100px) {
		html:not(.guest) .div_pose,
		html:not(.guest) .div_template,
		html:not(.guest) .div_output_image {
/*			width: calc(100% / 2 - 14px - 14px);*/
			width: calc(100% / 2 - 7px - 7px);
		}
		html.guest .div_output_image,
		html.guest .div_template,
		html.template .div_output_image {
			width: calc(100% / 3 - 7px - 7px);
		}
	}

    @media (max-width: 1100px) {
		html:not(.guest) .div_pose,
		html:not(.guest) .div_template,
		html:not(.guest) .div_output_image {
/*			width: calc(100% / 2 - 14px - 14px);*/
			width: calc(100% / 2 - 7px - 7px);
		}
		html.guest .div_output_image,
		html.guest .div_template,
		html.template .div_output_image {
			width: calc(100% / 3 - 7px - 7px);
		}
	}
	.templates a.div_template {
		text-decoration: none;
		margin: 5px;
	}

	@media (max-width: 600px) {
		html:not(.guest) .div_pose,
		html:not(.guest) .div_template,
		html:not(.guest) .div_output_image {
		}
		html.guest .div_template {
			width: calc(100% / 2 - 7px - 7px);
		}
	}
		
	@media (max-width: 500px) {
		html:not(.guest) .div_output_image {
		}
		html.guest .div_template {
			width: auto;
		}
	}

    .pricing h2,
	.templates h2,
	.feature h2,
	.faq h2 {
		font-size:42px;
	}

    .templates p,
	.feature p,
	.faq p {
		line-height:1.75;
		font-size:18px;
		overflow:hidden;
		margin-top:0;
		margin-bottom:0;
		transition: none;
	}

    .div_output_image.placeholder,
	.div_template {
		border: 1px solid var(--photo-border-color);
	}

    .div_pose,
	.div_template,
	.div_output_image {
		min-height:100px;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select:none;
		vertical-align: top;
		overflow: hidden;
		position: relative;
		display: inline-block;
/*			margin: 14px;*/
			margin: 7px;
		margin-top: 0;
/*		margin-bottom: 28px;*/
		margin-bottom: 14px;
		height: auto;
		border-radius: 12px;
		border: 1px solid var(--photo-border-color);
/*		width: calc(100% / 4 - 31px);*/
		width: calc(100% / 4 - 14px);
	}

	.div_output_image.camera {
		cursor:zoom-in;
	}

	@media (max-width: 1100px) {
		html:not(.guest) .div_pose, html:not(.guest) .div_template, html:not(.guest) .div_output_image {
			/* width: calc(100% / 2 - 14px - 14px); */
			width: calc(100% / 2 - 7px - 7px);
		}
	}

	@media (max-width: 800px) {
		html:not(.guest) .div_pose, html:not(.guest) .div_template, html:not(.guest) .div_output_image, .div_output_image.landscape {
			/* width: calc(100% - 14px); */
			width: calc(100% - 7px);
			/* width: calc(50% - 14px); */
			margin: 7px;
			margin-bottom: 14px;
		}
	}

	.pricing,
	.testimonials,
	.templates,
	.faq {
		max-width:1200px;
		margin:0 auto;
		margin-bottom:28px;
		margin-top:28px;
	}


	.filter-container {
		max-width: 1000px;
		margin: 0 auto;
		padding: 24px;
		position: relative;
		background-color: #0f172a;
  		color: #f8fafc;
	  }
	  
	  .filter-header {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin: 7px;
		margin-bottom: 17px;
	  }
	  
	  .filter-title {
		font-size: 24px;
		font-weight: bold;
		margin: 0;
		color: #f8fafc;
	  }
	  
	  .filter-button {
		display: flex;
		align-items: center;
		gap: 8px;
		padding: 8px 16px;
		border: 1px solid rgba(0, 0, 0, 0);
		border-radius: 6px;
		background: -webkit-linear-gradient(45deg, #680aca, #ff897e);
		background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
		-webkit-animation: animatedgradient 6s ease infinite alternate;
		animation: animatedgradient 6s ease infinite alternate;
		color: #f8fafc;
		cursor: pointer;
		transition: all 0.2s ease;
		background-size: 300% 300%;
	  }

	  @keyframes animatedgradient {
		0% {
			background-position: 0% 50%;
		}
		50% {
			background-position: 100% 50%;
		}
		100% {
			background-position: 0% 50%;
		}
	}
	  
	  .filter-button:hover {
		background: none;
		border: 1px solid var(--global-text-color);
	  }
	  
	  .filter-count {
		background: #111;
		color: white;
		padding: 2px 8px;
		border-radius: 12px;
		font-size: 12px;
		margin-left: 8px;
	  }
	  
	  .filter-backdrop {
		position: fixed;
		inset: 0;
		background: rgba(0, 0, 0, 0.7);
		z-index: 40;
	  }
	  
	  .filter-dropdown {
		position: absolute;
		right: 24px;
		top: 120px;
		width: 320px;
		background: #111;
		border-radius: 8px;
		box-shadow: 0 10px 25px -5px rgba(0, 0, 0, 0.3);
		z-index: 50;
		border: 1px solid rgba(255, 255, 255, 0.2);
	  }
	  
	  .filter-dropdown-header {
		padding: 16px;
		border-bottom: 1px solid #334155;
		display: flex;
		justify-content: space-between;
		align-items: center;
	  }
	  
	  .filter-dropdown-title {
		margin: 0;
		font-size: 16px;
		font-weight: 600;
	  }
	  
	  .close-button {
		background: none;
		border: none;
		cursor: pointer;
		padding: 4px;
		color: #64748b;
	  }
	  
	  .close-button:hover {
		color: #334155;
	  }
	  
	  .filter-content {
		padding: 16px;
		max-height: 300px;
		overflow-y: auto;
		/* For smoother scrolling on Safari/iOS */
		-webkit-overflow-scrolling: touch;
	  }
	  
	  .filter-item {
		display: flex;
		align-items: center;
		gap: 12px;
		padding: 8px;
		margin: 4px 0;
		border-radius: 6px;
		cursor: pointer;
		transition: background 0.2s;
	  }

	  .filter-item input[type="checkbox"] {
		accent-color: #10b981;
	  }
	  
	  .filter-item:hover {
		opacity: 0.75;
	  }
	  
	  .filter-footer {
		padding: 16px;
		border-top: 1px solid rgba(255, 255, 255, 0.2);
		background: #111;
		display: flex;
		justify-content: space-between;
		align-items: center;
	  }
	  
	  .clear-button {
		background: none;
		border: none;
		color: #64748b;
		cursor: pointer;
		padding: 8px 16px;
		font-size: 14px;
	  }
	  
	  .clear-button:hover {
		color: #334155;
	  }
	  
	  .apply-button {
		background: -webkit-linear-gradient(45deg, #680aca, #ff897e);
		background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
		-webkit-animation: animatedgradient 6s ease infinite alternate;
		animation: animatedgradient 6s ease infinite alternate;
		color: white;
		border: 1px solid rgba(0, 0, 0, 0);
		padding: 8px 16px;
		border-radius: 6px;
		cursor: pointer;
		font-size: 14px;
		transition: background-color 0.2s;
		background-size: 300% 300%;
	  }
	  
	  .apply-button:hover {
		background: none;
		border: 1px solid var(--global-text-color);
	  }
	  
	  .active-tags {
		display: flex;
		flex-wrap: wrap;
		gap: 8px;
		margin-bottom: 24px;
	  }
	  
	  .filter-tag {
		background: #f1f5f9;
		padding: 4px 12px;
		border-radius: 16px;
		font-size: 14px;
	  }
	  
	  /* Mobile Styles */
	  @media (max-width: 768px) {
		.filter-title {
		  font-size: 20px;
		}

		.filterContainer {
			margin-top: 14px;
		}
	  
		.filter-button {
		  padding: 10px 16px;
		}

		.filter-header {
			margin-top: 24px;
			justify-content: center;
		}
	  
		.filter-dropdown {
		  width: auto;
		  left: 16px;
		  right: 16px;
		  top: 80px;
		}
	  
		.filter-item {
		  padding: 12px 8px;
		}
	  
		.apply-button,
		.clear-button {
		  padding: 12px 20px;
		}
	  }
	  
	  @media (max-width: 480px) {
		.filter-backdrop {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: rgba(0, 0, 0, 0.7);
			z-index: 40;
		}

		.filter-dropdown {
			position: fixed;
			left: 0;
			right: 0;
			top: 0;
			height: 100vh;
			margin: 0;
			border-radius: 0;
			display: flex;
			flex-direction: column;
			animation: slideIn 0.3s ease-out;
		}

		.filter-content {
			max-height: none;
			flex: 1;
			height: auto;
			overflow-y: auto;
			padding: 16px;
		  }
		
		  .filter-footer {
			position: sticky;
			bottom: 0;
			left: 0;
			right: 0;
			padding: 16px 24px;
			background: #111;
			border-top: 1px solid rgba(255, 255, 255, 0.2);
			z-index: 2;
		  }
		
		  @keyframes slideIn {
			from {
			  transform: translateY(-100%);
			}
			to {
			  transform: translateY(0);
			}
		  }
	  }
