
.pricing-container h2,
.pricing-container p {
    margin-left:0;
    margin-right:0;
}
.pricing-container {
    margin:0 auto;
}
.pricing-container p.description {
    color:rgba(255,255,255,0.7);
    font-size:14px;
}
.pricing-container .toggle-switch {
    text-align: center;
    margin-bottom: 20px;
}
.pricing-container  .toggle-switch button {
    padding: 10px 20px;
    border: none;
    background-color: rgba(255,255,255,0.1);
    color:rgba(255,255,255,0.7);
    cursor: pointer;
    border-radius:12px;
    margin-left:3.5px;
    margin-right:3.5px;
    cursor:pointer;
    font-size:16px;
}
.pricing-container .toggle-switch button.active {
    background-color: rgb(244, 68, 37);
    color:#fff;
    font-weight:bold;
}
.pricing-container .toggle-switch button:hover {
    color:#fff;
}
.pricing-container .toggle-switch button:active {
    opacity:0.5;
}
.pricing-container  .pricing-wrapper {
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
    flex-wrap: wrap;
}
.pricing-container .pricing-plan {
    cursor:pointer;
    text-align:left;
    border-radius:12px;
    padding: 30px;
    width: 260px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    margin: 10px;
    position: relative;
    border:1px solid rgba(255, 255, 255,0.1);
    /* width:calc(25% - 30px - 30px - 30px); */
    width:calc(25% - 30px);
}
.pricing-container .pricing-plan:hover {
    opacity:1;
    border-color: rgba(255, 255, 255, 0.2);
    background-color:rgba(255, 255, 255, 0.1);
}
.pricing-container .pricing-plan {
    opacity:0.8;
}
.pricing-container .pricing-plan.popular {
    opacity:1;
}
.pricing-container .pricing-plan:active {
    opacity:0.75;
}


@media (max-width:1200px) {
    .pricing-container  .pricing-plan {
        width:calc(33% - 30px - 30px - 30px);
    }
}
@media (max-width:1000px) {
    .pricing-container  .pricing-plan {
        width:calc(50% - 30px);
    }
}
@media (max-width:800px) {
    .pricing-container  .pricing-plan {
        width:calc(100%);
    }
}
.pricing-container .pricing-plan .pricing-badge-container {
    height: 30px;
}
.pricing-container .pricing-plan .pricing-badge {
    padding: 5px 10px;
    position: relative;
    overflow: hidden;
    background: rgba(0, 0, 0, .2);
    display: inline-block;
    border-radius: 12px;
    font-size: 12px;
}
.pricing-container .pricing-plan .pricing-badge:before {
    animation: badgeShimmer 3.5s infinite ease-out 1.8s;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .18) 25%, rgba(255, 255, 255, .3) 50%, rgba(255, 255, 255, .18) 75%, rgba(255, 255, 255, 0) 100%);
    content: "";
    display: block;
    height: 90px;
    left: -60%;
    position: absolute;
    top: -150px;
    transform: rotate(-25deg);
    width: 200px;
}
@keyframes badgeShimmer {
    0% {
        left: -100%;
        top: -150px;
    }
    50% {
        left: 100%;
        top: 150px;
    }
    100% {
        left: 100%;
        top: 150px;
    }
}
.pricing-container .pricing-plan .badge span {
    font-size:12px;
    font-weight:500;
}
.pricing-container .pricing-plan.popular {
    border-color: rgba(255, 255, 255, 0.2);
    background-color:rgba(255, 255, 255, 0.1);
}
.pricing-container .pricing-plan.monthly {
    display:none;
}
.pricing-container .pricing-plan.yearly {
    display:none;
}
.pricing-container .pricing-plan h2 {
    font-size: 20px;
}
.pricing-container .price span.number {
    font-size: 45px;
    color: #fff;
    font-weight:bold;
    margin-right:7px;
}

.pricing-container .price strong {
    color:#fff;
}
.pricing-container .price span {
    font-size: 12px;
    font-weight:normal;
    color:rgba(255,255,255,0.7);
    display:inline-block;
}
.pricing-container  .features {
    list-style: none;
    padding: 0;
}
.pricing-container  .features strong li .check {
    opacity:1;
}
.pricing-container .features li {
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 0;
    padding-right: 0;
    color:rgba(255,255,255,0.7);
    font-size:14px;
}
.pricing-container  .features li.strong,
.pricing-container  .features strong li {
    color:#fff;
    font-weight:bold;
}
.pricing {
    max-width:1400px;
}
.pricing-container .features li:last-child {
    border-bottom: none;
}
.pricing-container  .features li span {
    vertical-align:middle;
}
.pricing-container  .features li .check {
    opacity:0.7;
    background-image:url('../assets/pricing-table-check.svg?8');
    background-size:cover;
    width:15px;
    height:15px;
    display:inline-block;
    margin-right:7px;
    vertical-align:middle;
}
.pricing-container button.subscribe {
    background-color: rgb(244, 68, 37);
    color: white;
    border: none;
    padding: 12px 22px;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
    font-size:16px;
    border-radius:12px;
    font-weight:600;
    border:1px solid rgb(244, 68, 37);
}
.pricing-container button.subscribe.disabled:hover {
    background:rgb(244, 68, 37);
    border:1px solid rgb(244, 68, 37);
}
.pricing-container button.subscribe:hover {
    background:none;
    color:rgb(244, 68, 37);
    border:1px solid rgb(244, 68, 37);
}
.pricing-container button.subscribe:active {
    opacity:0.5;
}
.pricing-container button.current {
    background:#fff;
    color:#000;
    pointer-events:none;
    border-color:#fff;
}
.action-switch-to-monthly-billing,
.action-switch-to-annual-billing {
    font-size:12px;
    cursor:pointer;
    text-align:center;
    margin-top:14px;
}
.action-switch-to-monthly-billing:hover,
.action-switch-to-annual-billing:hover {
    text-decoration:underline;
}